.App {
  text-align: center;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  background-image: url('/images/sunbrella-canvas.jpg');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #000000;
  padding-bottom: 10em;
}

.title {
  font-size: 2em;
  padding: 1em 0;
}

.banner-image-container {
  margin-top: 4em;
  width: 80%;
  border: 1em solid black;
  transform: rotate(90deg);
}

.banner-image {
  width: 100%;
}

.image-gallery-container {
  width: 80%;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

